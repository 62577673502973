<template>
  <v-row justify-md="center" align="stretch">
    <v-col md="4">
      <PasswordResetCard :token="token" v-if="token"/>
    </v-col>
  </v-row>
</template>

<script>
import PasswordResetCard from '@/components/Authorisation/PasswordResetCard.vue';

export default {
  name: 'PasswordReset',
  components: {
    PasswordResetCard,
  },
  data: () => ({
    token: null,
  }),
  created() {
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setTitle', 'Wachtwoord aanvragen');
    this.$hero.$emit('setBreadcrumbs', []);

    this.token = this.$route.params.token;
  },
};
</script>

<style scoped>

</style>
