<template>
  <v-card height="100%">
    <v-form @submit.prevent="handleRegister()" ref="form" v-model="valid">
      <v-card-title class="text-h6">Ik wil een wachtwoord aanvragen</v-card-title>
      <v-card-text>
        <v-alert
            :value="!!alertMessage.length"
            class="mb-10"
            transition="fade-transition"
            :type="alertType"
        >
          {{ alertMessage }}
        </v-alert>
        <VTextField
            :rules="[(v) => !!v || 'E-Mail is verplicht', () => serverError('email')]"
            label="E-Mail"
            v-model="form.email"
            tabindex="1"
        />
        <VTextField
            :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
            :rules="[(v) => !!v || 'Wachtwoord is verplicht', () => serverError('password')]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            label="Wachtwoord"
            v-model="form.password"
            tabindex="2"
        />
        <VTextField
            :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
            :rules="[(v) => !!v || 'Wachtwoord is verplicht', () => serverError('passwordConfirmation')]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            label="Herhaal wachtwoord"
            v-model="form.passwordConfirmation"
            tabindex="3"
        />
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn tabindex="4" type="submit" color="primary" :loading="isLoading">Wachtwoord aanvragen</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import BaseForm from './../BaseForm';
import { passwordReset } from '../../api/endpoints/authorisation/password.js';

export default {
  name: 'PasswordResetCard',
  extends: BaseForm,
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alertType: 'info',
      alertMessage: '',
      isLoading: false,
      form: {
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      showPassword: false,
    };
  },
  methods: {
    handleRegister() {
      this.validate();
      if (!this.valid) {
        return;
      }
      this.isLoading = true;

      passwordReset(this.token, this.form.email, this.form.password, this.form.passwordConfirmation)
          .then(() => {
            this.alertType = 'success';
            this.alertMessage = 'U wachtwoord is gereset.';
          })
          .catch(() => {
            this.alertType = 'error';
            this.alertMessage = 'Er is iets misgegaan.';
          })
          .finally(() => {
            this.isLoading = false;
            this.validate();
          });
    },
  },
};
</script>
